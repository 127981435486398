module.exports = [
  { id: 1000, zipcode: 100, city: '臺北市', area: '中正區' },
  { id: 1030, zipcode: 103, city: '臺北市', area: '大同區' },
  { id: 1040, zipcode: 104, city: '臺北市', area: '中山區' },
  { id: 1050, zipcode: 105, city: '臺北市', area: '松山區' },
  { id: 1060, zipcode: 106, city: '臺北市', area: '大安區' },
  { id: 1080, zipcode: 108, city: '臺北市', area: '萬華區' },
  { id: 1100, zipcode: 110, city: '臺北市', area: '信義區' },
  { id: 1110, zipcode: 111, city: '臺北市', area: '士林區' },
  { id: 1120, zipcode: 112, city: '臺北市', area: '北投區' },
  { id: 1140, zipcode: 114, city: '臺北市', area: '內湖區' },
  { id: 1150, zipcode: 115, city: '臺北市', area: '南港區' },
  { id: 1160, zipcode: 116, city: '臺北市', area: '文山區' },
  { id: 2000, zipcode: 200, city: '基隆市', area: '仁愛區' },
  { id: 2010, zipcode: 201, city: '基隆市', area: '信義區' },
  { id: 2020, zipcode: 202, city: '基隆市', area: '中正區' },
  { id: 2030, zipcode: 203, city: '基隆市', area: '中山區' },
  { id: 2040, zipcode: 204, city: '基隆市', area: '安樂區' },
  { id: 2050, zipcode: 205, city: '基隆市', area: '暖暖區' },
  { id: 2060, zipcode: 206, city: '基隆市', area: '七堵區' },
  { id: 2070, zipcode: 207, city: '新北市', area: '萬里區' },
  { id: 2080, zipcode: 208, city: '新北市', area: '金山區' },
  { id: 2200, zipcode: 220, city: '新北市', area: '板橋區' },
  { id: 2210, zipcode: 221, city: '新北市', area: '汐止區' },
  { id: 2220, zipcode: 222, city: '新北市', area: '深坑區' },
  { id: 2230, zipcode: 223, city: '新北市', area: '石碇區' },
  { id: 2240, zipcode: 224, city: '新北市', area: '瑞芳區' },
  { id: 2260, zipcode: 226, city: '新北市', area: '平溪區' },
  { id: 2270, zipcode: 227, city: '新北市', area: '雙溪區' },
  { id: 2280, zipcode: 228, city: '新北市', area: '貢寮區' },
  { id: 2310, zipcode: 231, city: '新北市', area: '新店區' },
  { id: 2320, zipcode: 232, city: '新北市', area: '坪林區' },
  { id: 2330, zipcode: 233, city: '新北市', area: '烏來區' },
  { id: 2340, zipcode: 234, city: '新北市', area: '永和區' },
  { id: 2350, zipcode: 235, city: '新北市', area: '中和區' },
  { id: 2360, zipcode: 236, city: '新北市', area: '土城區' },
  { id: 2370, zipcode: 237, city: '新北市', area: '三峽區' },
  { id: 2380, zipcode: 238, city: '新北市', area: '樹林區' },
  { id: 2390, zipcode: 239, city: '新北市', area: '鶯歌區' },
  { id: 2410, zipcode: 241, city: '新北市', area: '三重區' },
  { id: 2420, zipcode: 242, city: '新北市', area: '新莊區' },
  { id: 2430, zipcode: 243, city: '新北市', area: '泰山區' },
  { id: 2440, zipcode: 244, city: '新北市', area: '林口區' },
  { id: 2470, zipcode: 247, city: '新北市', area: '蘆洲區' },
  { id: 2480, zipcode: 248, city: '新北市', area: '五股區' },
  { id: 2490, zipcode: 249, city: '新北市', area: '八里區' },
  { id: 2510, zipcode: 251, city: '新北市', area: '淡水區' },
  { id: 2520, zipcode: 252, city: '新北市', area: '三芝區' },
  { id: 2530, zipcode: 253, city: '新北市', area: '石門區' },
  { id: 2600, zipcode: 260, city: '宜蘭縣', area: '宜蘭市' },
  { id: 2610, zipcode: 261, city: '宜蘭縣', area: '頭城鎮' },
  { id: 2620, zipcode: 262, city: '宜蘭縣', area: '礁溪鄉' },
  { id: 2630, zipcode: 263, city: '宜蘭縣', area: '壯圍鄉' },
  { id: 2640, zipcode: 264, city: '宜蘭縣', area: '員山鄉' },
  { id: 2650, zipcode: 265, city: '宜蘭縣', area: '羅東鎮' },
  { id: 2660, zipcode: 266, city: '宜蘭縣', area: '三星鄉' },
  { id: 2670, zipcode: 267, city: '宜蘭縣', area: '大同鄉' },
  { id: 2680, zipcode: 268, city: '宜蘭縣', area: '五結鄉' },
  { id: 2690, zipcode: 269, city: '宜蘭縣', area: '冬山鄉' },
  { id: 2700, zipcode: 270, city: '宜蘭縣', area: '蘇澳鎮' },
  { id: 2720, zipcode: 272, city: '宜蘭縣', area: '南澳鄉' },
  { id: 2900, zipcode: 290, city: '宜蘭縣', area: '釣魚臺列嶼' },
  { id: 3000, zipcode: 300, city: '新竹市', area: '東區' },
  { id: 3001, zipcode: 300, city: '新竹市', area: '北區' },
  { id: 3002, zipcode: 300, city: '新竹市', area: '香山區' },
  { id: 3020, zipcode: 302, city: '新竹縣', area: '竹北市' },
  { id: 3030, zipcode: 303, city: '新竹縣', area: '湖口鄉' },
  { id: 3040, zipcode: 304, city: '新竹縣', area: '新豐鄉' },
  { id: 3050, zipcode: 305, city: '新竹縣', area: '新埔鎮' },
  { id: 3060, zipcode: 306, city: '新竹縣', area: '關西鎮' },
  { id: 3070, zipcode: 307, city: '新竹縣', area: '芎林鄉' },
  { id: 3080, zipcode: 308, city: '新竹縣', area: '寶山鄉' },
  { id: 3100, zipcode: 310, city: '新竹縣', area: '竹東鎮' },
  { id: 3110, zipcode: 311, city: '新竹縣', area: '五峰鄉' },
  { id: 3120, zipcode: 312, city: '新竹縣', area: '橫山鄉' },
  { id: 3130, zipcode: 313, city: '新竹縣', area: '尖石鄉' },
  { id: 3140, zipcode: 314, city: '新竹縣', area: '北埔鄉' },
  { id: 3150, zipcode: 315, city: '新竹縣', area: '峨眉鄉' },
  { id: 3200, zipcode: 320, city: '桃園市', area: '中壢區' },
  { id: 3240, zipcode: 324, city: '桃園市', area: '平鎮區' },
  { id: 3250, zipcode: 325, city: '桃園市', area: '龍潭區' },
  { id: 3260, zipcode: 326, city: '桃園市', area: '楊梅區' },
  { id: 3270, zipcode: 327, city: '桃園市', area: '新屋區' },
  { id: 3280, zipcode: 328, city: '桃園市', area: '觀音區' },
  { id: 3300, zipcode: 330, city: '桃園市', area: '桃園區' },
  { id: 3330, zipcode: 333, city: '桃園市', area: '龜山區' },
  { id: 3340, zipcode: 334, city: '桃園市', area: '八德區' },
  { id: 3350, zipcode: 335, city: '桃園市', area: '大溪區' },
  { id: 3360, zipcode: 336, city: '桃園市', area: '復興區' },
  { id: 3370, zipcode: 337, city: '桃園市', area: '大園區' },
  { id: 3380, zipcode: 338, city: '桃園市', area: '蘆竹區' },
  { id: 3500, zipcode: 350, city: '苗栗縣', area: '竹南鎮' },
  { id: 3510, zipcode: 351, city: '苗栗縣', area: '頭份市' },
  { id: 3520, zipcode: 352, city: '苗栗縣', area: '三灣鄉' },
  { id: 3530, zipcode: 353, city: '苗栗縣', area: '南庄鄉' },
  { id: 3540, zipcode: 354, city: '苗栗縣', area: '獅潭鄉' },
  { id: 3560, zipcode: 356, city: '苗栗縣', area: '後龍鎮' },
  { id: 3570, zipcode: 357, city: '苗栗縣', area: '通霄鎮' },
  { id: 3580, zipcode: 358, city: '苗栗縣', area: '苑裡鎮' },
  { id: 3600, zipcode: 360, city: '苗栗縣', area: '苗栗市' },
  { id: 3610, zipcode: 361, city: '苗栗縣', area: '造橋鄉' },
  { id: 3620, zipcode: 362, city: '苗栗縣', area: '頭屋鄉' },
  { id: 3630, zipcode: 363, city: '苗栗縣', area: '公館鄉' },
  { id: 3640, zipcode: 364, city: '苗栗縣', area: '大湖鄉' },
  { id: 3650, zipcode: 365, city: '苗栗縣', area: '泰安鄉' },
  { id: 3660, zipcode: 366, city: '苗栗縣', area: '銅鑼鄉' },
  { id: 3670, zipcode: 367, city: '苗栗縣', area: '三義鄉' },
  { id: 3680, zipcode: 368, city: '苗栗縣', area: '西湖鄉' },
  { id: 3690, zipcode: 369, city: '苗栗縣', area: '卓蘭鎮' },
  { id: 4000, zipcode: 400, city: '臺中市', area: '中區' },
  { id: 4010, zipcode: 401, city: '臺中市', area: '東區' },
  { id: 4020, zipcode: 402, city: '臺中市', area: '南區' },
  { id: 4030, zipcode: 403, city: '臺中市', area: '西區' },
  { id: 4040, zipcode: 404, city: '臺中市', area: '北區' },
  { id: 4060, zipcode: 406, city: '臺中市', area: '北屯區' },
  { id: 4070, zipcode: 407, city: '臺中市', area: '西屯區' },
  { id: 4080, zipcode: 408, city: '臺中市', area: '南屯區' },
  { id: 4110, zipcode: 411, city: '臺中市', area: '太平區' },
  { id: 4120, zipcode: 412, city: '臺中市', area: '大里區' },
  { id: 4130, zipcode: 413, city: '臺中市', area: '霧峰區' },
  { id: 4140, zipcode: 414, city: '臺中市', area: '烏日區' },
  { id: 4200, zipcode: 420, city: '臺中市', area: '豐原區' },
  { id: 4210, zipcode: 421, city: '臺中市', area: '后里區' },
  { id: 4220, zipcode: 422, city: '臺中市', area: '石岡區' },
  { id: 4230, zipcode: 423, city: '臺中市', area: '東勢區' },
  { id: 4240, zipcode: 424, city: '臺中市', area: '和平區' },
  { id: 4260, zipcode: 426, city: '臺中市', area: '新社區' },
  { id: 4270, zipcode: 427, city: '臺中市', area: '潭子區' },
  { id: 4280, zipcode: 428, city: '臺中市', area: '大雅區' },
  { id: 4290, zipcode: 429, city: '臺中市', area: '神岡區' },
  { id: 4320, zipcode: 432, city: '臺中市', area: '大肚區' },
  { id: 4330, zipcode: 433, city: '臺中市', area: '沙鹿區' },
  { id: 4340, zipcode: 434, city: '臺中市', area: '龍井區' },
  { id: 4350, zipcode: 435, city: '臺中市', area: '梧棲區' },
  { id: 4360, zipcode: 436, city: '臺中市', area: '清水區' },
  { id: 4370, zipcode: 437, city: '臺中市', area: '大甲區' },
  { id: 4380, zipcode: 438, city: '臺中市', area: '外埔區' },
  { id: 4390, zipcode: 439, city: '臺中市', area: '大安區' },
  { id: 5000, zipcode: 500, city: '彰化縣', area: '彰化市' },
  { id: 5020, zipcode: 502, city: '彰化縣', area: '芬園鄉' },
  { id: 5030, zipcode: 503, city: '彰化縣', area: '花壇鄉' },
  { id: 5040, zipcode: 504, city: '彰化縣', area: '秀水鄉' },
  { id: 5050, zipcode: 505, city: '彰化縣', area: '鹿港鎮' },
  { id: 5060, zipcode: 506, city: '彰化縣', area: '福興鄉' },
  { id: 5070, zipcode: 507, city: '彰化縣', area: '線西鄉' },
  { id: 5080, zipcode: 508, city: '彰化縣', area: '和美鎮' },
  { id: 5090, zipcode: 509, city: '彰化縣', area: '伸港鄉' },
  { id: 5100, zipcode: 510, city: '彰化縣', area: '員林市' },
  { id: 5110, zipcode: 511, city: '彰化縣', area: '社頭鄉' },
  { id: 5120, zipcode: 512, city: '彰化縣', area: '永靖鄉' },
  { id: 5130, zipcode: 513, city: '彰化縣', area: '埔心鄉' },
  { id: 5140, zipcode: 514, city: '彰化縣', area: '溪湖鎮' },
  { id: 5150, zipcode: 515, city: '彰化縣', area: '大村鄉' },
  { id: 5160, zipcode: 516, city: '彰化縣', area: '埔鹽鄉' },
  { id: 5200, zipcode: 520, city: '彰化縣', area: '田中鎮' },
  { id: 5210, zipcode: 521, city: '彰化縣', area: '北斗鎮' },
  { id: 5220, zipcode: 522, city: '彰化縣', area: '田尾鄉' },
  { id: 5230, zipcode: 523, city: '彰化縣', area: '埤頭鄉' },
  { id: 5240, zipcode: 524, city: '彰化縣', area: '溪州鄉' },
  { id: 5250, zipcode: 525, city: '彰化縣', area: '竹塘鄉' },
  { id: 5260, zipcode: 526, city: '彰化縣', area: '二林鎮' },
  { id: 5270, zipcode: 527, city: '彰化縣', area: '大城鄉' },
  { id: 5280, zipcode: 528, city: '彰化縣', area: '芳苑鄉' },
  { id: 5300, zipcode: 530, city: '彰化縣', area: '二水鄉' },
  { id: 5400, zipcode: 540, city: '南投縣', area: '南投市' },
  { id: 5410, zipcode: 541, city: '南投縣', area: '中寮鄉' },
  { id: 5420, zipcode: 542, city: '南投縣', area: '草屯鎮' },
  { id: 5440, zipcode: 544, city: '南投縣', area: '國姓鄉' },
  { id: 5450, zipcode: 545, city: '南投縣', area: '埔里鎮' },
  { id: 5460, zipcode: 546, city: '南投縣', area: '仁愛鄉' },
  { id: 5510, zipcode: 551, city: '南投縣', area: '名間鄉' },
  { id: 5520, zipcode: 552, city: '南投縣', area: '集集鎮' },
  { id: 5530, zipcode: 553, city: '南投縣', area: '水里鄉' },
  { id: 5550, zipcode: 555, city: '南投縣', area: '魚池鄉' },
  { id: 5560, zipcode: 556, city: '南投縣', area: '信義鄉' },
  { id: 5570, zipcode: 557, city: '南投縣', area: '竹山鎮' },
  { id: 5580, zipcode: 558, city: '南投縣', area: '鹿谷鄉' },
  { id: 6000, zipcode: 600, city: '嘉義市', area: '東區' },
  { id: 6001, zipcode: 600, city: '嘉義市', area: '西區' },
  { id: 6020, zipcode: 602, city: '嘉義縣', area: '番路鄉' },
  { id: 6030, zipcode: 603, city: '嘉義縣', area: '梅山鄉' },
  { id: 6040, zipcode: 604, city: '嘉義縣', area: '竹崎鄉' },
  { id: 6050, zipcode: 605, city: '嘉義縣', area: '阿里山鄉' },
  { id: 6060, zipcode: 606, city: '嘉義縣', area: '中埔鄉' },
  { id: 6070, zipcode: 607, city: '嘉義縣', area: '大埔鄉' },
  { id: 6080, zipcode: 608, city: '嘉義縣', area: '水上鄉' },
  { id: 6110, zipcode: 611, city: '嘉義縣', area: '鹿草鄉' },
  { id: 6120, zipcode: 612, city: '嘉義縣', area: '太保市' },
  { id: 6130, zipcode: 613, city: '嘉義縣', area: '朴子市' },
  { id: 6140, zipcode: 614, city: '嘉義縣', area: '東石鄉' },
  { id: 6150, zipcode: 615, city: '嘉義縣', area: '六腳鄉' },
  { id: 6160, zipcode: 616, city: '嘉義縣', area: '新港鄉' },
  { id: 6210, zipcode: 621, city: '嘉義縣', area: '民雄鄉' },
  { id: 6220, zipcode: 622, city: '嘉義縣', area: '大林鎮' },
  { id: 6230, zipcode: 623, city: '嘉義縣', area: '溪口鄉' },
  { id: 6240, zipcode: 624, city: '嘉義縣', area: '義竹鄉' },
  { id: 6250, zipcode: 625, city: '嘉義縣', area: '布袋鎮' },
  { id: 6300, zipcode: 630, city: '雲林縣', area: '斗南鎮' },
  { id: 6310, zipcode: 631, city: '雲林縣', area: '大埤鄉' },
  { id: 6320, zipcode: 632, city: '雲林縣', area: '虎尾鎮' },
  { id: 6330, zipcode: 633, city: '雲林縣', area: '土庫鎮' },
  { id: 6340, zipcode: 634, city: '雲林縣', area: '褒忠鄉' },
  { id: 6350, zipcode: 635, city: '雲林縣', area: '東勢鄉' },
  { id: 6360, zipcode: 636, city: '雲林縣', area: '臺西鄉' },
  { id: 6370, zipcode: 637, city: '雲林縣', area: '崙背鄉' },
  { id: 6380, zipcode: 638, city: '雲林縣', area: '麥寮鄉' },
  { id: 6400, zipcode: 640, city: '雲林縣', area: '斗六市' },
  { id: 6430, zipcode: 643, city: '雲林縣', area: '林內鄉' },
  { id: 6460, zipcode: 646, city: '雲林縣', area: '古坑鄉' },
  { id: 6470, zipcode: 647, city: '雲林縣', area: '莿桐鄉' },
  { id: 6480, zipcode: 648, city: '雲林縣', area: '西螺鎮' },
  { id: 6490, zipcode: 649, city: '雲林縣', area: '二崙鄉' },
  { id: 6510, zipcode: 651, city: '雲林縣', area: '北港鎮' },
  { id: 6520, zipcode: 652, city: '雲林縣', area: '水林鄉' },
  { id: 6530, zipcode: 653, city: '雲林縣', area: '口湖鄉' },
  { id: 6540, zipcode: 654, city: '雲林縣', area: '四湖鄉' },
  { id: 6550, zipcode: 655, city: '雲林縣', area: '元長鄉' },
  { id: 7000, zipcode: 700, city: '臺南市', area: '中西區' },
  { id: 7010, zipcode: 701, city: '臺南市', area: '東區' },
  { id: 7020, zipcode: 702, city: '臺南市', area: '南區' },
  { id: 7040, zipcode: 704, city: '臺南市', area: '北區' },
  { id: 7080, zipcode: 708, city: '臺南市', area: '安平區' },
  { id: 7090, zipcode: 709, city: '臺南市', area: '安南區' },
  { id: 7100, zipcode: 710, city: '臺南市', area: '永康區' },
  { id: 7110, zipcode: 711, city: '臺南市', area: '歸仁區' },
  { id: 7120, zipcode: 712, city: '臺南市', area: '新化區' },
  { id: 7130, zipcode: 713, city: '臺南市', area: '左鎮區' },
  { id: 7140, zipcode: 714, city: '臺南市', area: '玉井區' },
  { id: 7150, zipcode: 715, city: '臺南市', area: '楠西區' },
  { id: 7160, zipcode: 716, city: '臺南市', area: '南化區' },
  { id: 7170, zipcode: 717, city: '臺南市', area: '仁德區' },
  { id: 7180, zipcode: 718, city: '臺南市', area: '關廟區' },
  { id: 7190, zipcode: 719, city: '臺南市', area: '龍崎區' },
  { id: 7200, zipcode: 720, city: '臺南市', area: '官田區' },
  { id: 7210, zipcode: 721, city: '臺南市', area: '麻豆區' },
  { id: 7220, zipcode: 722, city: '臺南市', area: '佳里區' },
  { id: 7230, zipcode: 723, city: '臺南市', area: '西港區' },
  { id: 7240, zipcode: 724, city: '臺南市', area: '七股區' },
  { id: 7250, zipcode: 725, city: '臺南市', area: '將軍區' },
  { id: 7260, zipcode: 726, city: '臺南市', area: '學甲區' },
  { id: 7270, zipcode: 727, city: '臺南市', area: '北門區' },
  { id: 7300, zipcode: 730, city: '臺南市', area: '新營區' },
  { id: 7310, zipcode: 731, city: '臺南市', area: '後壁區' },
  { id: 7320, zipcode: 732, city: '臺南市', area: '白河區' },
  { id: 7330, zipcode: 733, city: '臺南市', area: '東山區' },
  { id: 7340, zipcode: 734, city: '臺南市', area: '六甲區' },
  { id: 7350, zipcode: 735, city: '臺南市', area: '下營區' },
  { id: 7360, zipcode: 736, city: '臺南市', area: '柳營區' },
  { id: 7370, zipcode: 737, city: '臺南市', area: '鹽水區' },
  { id: 7410, zipcode: 741, city: '臺南市', area: '善化區' },
  { id: 7420, zipcode: 742, city: '臺南市', area: '大內區' },
  { id: 7430, zipcode: 743, city: '臺南市', area: '山上區' },
  { id: 7440, zipcode: 744, city: '臺南市', area: '新市區' },
  { id: 7450, zipcode: 745, city: '臺南市', area: '安定區' },
  { id: 8000, zipcode: 800, city: '高雄市', area: '新興區' },
  { id: 8010, zipcode: 801, city: '高雄市', area: '前金區' },
  { id: 8020, zipcode: 802, city: '高雄市', area: '苓雅區' },
  { id: 8030, zipcode: 803, city: '高雄市', area: '鹽埕區' },
  { id: 8040, zipcode: 804, city: '高雄市', area: '鼓山區' },
  { id: 8050, zipcode: 805, city: '高雄市', area: '旗津區' },
  { id: 8060, zipcode: 806, city: '高雄市', area: '前鎮區' },
  { id: 8070, zipcode: 807, city: '高雄市', area: '三民區' },
  { id: 8110, zipcode: 811, city: '高雄市', area: '楠梓區' },
  { id: 8120, zipcode: 812, city: '高雄市', area: '小港區' },
  { id: 8130, zipcode: 813, city: '高雄市', area: '左營區' },
  { id: 8140, zipcode: 814, city: '高雄市', area: '仁武區' },
  { id: 8150, zipcode: 815, city: '高雄市', area: '大社區' },
  { id: 8200, zipcode: 820, city: '高雄市', area: '岡山區' },
  { id: 8210, zipcode: 821, city: '高雄市', area: '路竹區' },
  { id: 8220, zipcode: 822, city: '高雄市', area: '阿蓮區' },
  { id: 8230, zipcode: 823, city: '高雄市', area: '田寮區' },
  { id: 8240, zipcode: 824, city: '高雄市', area: '燕巢區' },
  { id: 8250, zipcode: 825, city: '高雄市', area: '橋頭區' },
  { id: 8260, zipcode: 826, city: '高雄市', area: '梓官區' },
  { id: 8270, zipcode: 827, city: '高雄市', area: '彌陀區' },
  { id: 8280, zipcode: 828, city: '高雄市', area: '永安區' },
  { id: 8290, zipcode: 829, city: '高雄市', area: '湖內區' },
  { id: 8300, zipcode: 830, city: '高雄市', area: '鳳山區' },
  { id: 8310, zipcode: 831, city: '高雄市', area: '大寮區' },
  { id: 8320, zipcode: 832, city: '高雄市', area: '林園區' },
  { id: 8330, zipcode: 833, city: '高雄市', area: '鳥松區' },
  { id: 8400, zipcode: 840, city: '高雄市', area: '大樹區' },
  { id: 8420, zipcode: 842, city: '高雄市', area: '旗山區' },
  { id: 8430, zipcode: 843, city: '高雄市', area: '美濃區' },
  { id: 8440, zipcode: 844, city: '高雄市', area: '六龜區' },
  { id: 8450, zipcode: 845, city: '高雄市', area: '內門區' },
  { id: 8460, zipcode: 846, city: '高雄市', area: '杉林區' },
  { id: 8470, zipcode: 847, city: '高雄市', area: '甲仙區' },
  { id: 8480, zipcode: 848, city: '高雄市', area: '桃源區' },
  { id: 8490, zipcode: 849, city: '高雄市', area: '那瑪夏區' },
  { id: 8510, zipcode: 851, city: '高雄市', area: '茂林區' },
  { id: 8520, zipcode: 852, city: '高雄市', area: '茄萣區' },
  { id: 8170, zipcode: 817, city: '高雄市', area: '東沙群島' },
  { id: 8190, zipcode: 819, city: '高雄市', area: '南沙群島' },
  { id: 8800, zipcode: 880, city: '澎湖縣', area: '馬公市' },
  { id: 8810, zipcode: 881, city: '澎湖縣', area: '西嶼鄉' },
  { id: 8820, zipcode: 882, city: '澎湖縣', area: '望安鄉' },
  { id: 8830, zipcode: 883, city: '澎湖縣', area: '七美鄉' },
  { id: 8840, zipcode: 884, city: '澎湖縣', area: '白沙鄉' },
  { id: 8850, zipcode: 885, city: '澎湖縣', area: '湖西鄉' },
  { id: 9000, zipcode: 900, city: '屏東縣', area: '屏東市' },
  { id: 9010, zipcode: 901, city: '屏東縣', area: '三地門鄉' },
  { id: 9020, zipcode: 902, city: '屏東縣', area: '霧臺鄉' },
  { id: 9030, zipcode: 903, city: '屏東縣', area: '瑪家鄉' },
  { id: 9040, zipcode: 904, city: '屏東縣', area: '九如鄉' },
  { id: 9050, zipcode: 905, city: '屏東縣', area: '里港鄉' },
  { id: 9060, zipcode: 906, city: '屏東縣', area: '高樹鄉' },
  { id: 9070, zipcode: 907, city: '屏東縣', area: '鹽埔鄉' },
  { id: 9080, zipcode: 908, city: '屏東縣', area: '長治鄉' },
  { id: 9090, zipcode: 909, city: '屏東縣', area: '麟洛鄉' },
  { id: 9110, zipcode: 911, city: '屏東縣', area: '竹田鄉' },
  { id: 9120, zipcode: 912, city: '屏東縣', area: '內埔鄉' },
  { id: 9130, zipcode: 913, city: '屏東縣', area: '萬丹鄉' },
  { id: 9200, zipcode: 920, city: '屏東縣', area: '潮州鎮' },
  { id: 9210, zipcode: 921, city: '屏東縣', area: '泰武鄉' },
  { id: 9220, zipcode: 922, city: '屏東縣', area: '來義鄉' },
  { id: 9230, zipcode: 923, city: '屏東縣', area: '萬巒鄉' },
  { id: 9240, zipcode: 924, city: '屏東縣', area: '崁頂鄉' },
  { id: 9250, zipcode: 925, city: '屏東縣', area: '新埤鄉' },
  { id: 9260, zipcode: 926, city: '屏東縣', area: '南州鄉' },
  { id: 9270, zipcode: 927, city: '屏東縣', area: '林邊鄉' },
  { id: 9280, zipcode: 928, city: '屏東縣', area: '東港鎮' },
  { id: 9290, zipcode: 929, city: '屏東縣', area: '琉球鄉' },
  { id: 9310, zipcode: 931, city: '屏東縣', area: '佳冬鄉' },
  { id: 9320, zipcode: 932, city: '屏東縣', area: '新園鄉' },
  { id: 9400, zipcode: 940, city: '屏東縣', area: '枋寮鄉' },
  { id: 9410, zipcode: 941, city: '屏東縣', area: '枋山鄉' },
  { id: 9420, zipcode: 942, city: '屏東縣', area: '春日鄉' },
  { id: 9430, zipcode: 943, city: '屏東縣', area: '獅子鄉' },
  { id: 9440, zipcode: 944, city: '屏東縣', area: '車城鄉' },
  { id: 9450, zipcode: 945, city: '屏東縣', area: '牡丹鄉' },
  { id: 9460, zipcode: 946, city: '屏東縣', area: '恆春鎮' },
  { id: 9470, zipcode: 947, city: '屏東縣', area: '滿州鄉' },
  { id: 9500, zipcode: 950, city: '臺東縣', area: '臺東市' },
  { id: 9510, zipcode: 951, city: '臺東縣', area: '綠島鄉' },
  { id: 9520, zipcode: 952, city: '臺東縣', area: '蘭嶼鄉' },
  { id: 9530, zipcode: 953, city: '臺東縣', area: '延平鄉' },
  { id: 9540, zipcode: 954, city: '臺東縣', area: '卑南鄉' },
  { id: 9550, zipcode: 955, city: '臺東縣', area: '鹿野鄉' },
  { id: 9560, zipcode: 956, city: '臺東縣', area: '關山鎮' },
  { id: 9570, zipcode: 957, city: '臺東縣', area: '海端鄉' },
  { id: 9580, zipcode: 958, city: '臺東縣', area: '池上鄉' },
  { id: 9590, zipcode: 959, city: '臺東縣', area: '東河鄉' },
  { id: 9610, zipcode: 961, city: '臺東縣', area: '成功鎮' },
  { id: 9620, zipcode: 962, city: '臺東縣', area: '長濱鄉' },
  { id: 9630, zipcode: 963, city: '臺東縣', area: '太麻里鄉' },
  { id: 9640, zipcode: 964, city: '臺東縣', area: '金峰鄉' },
  { id: 9650, zipcode: 965, city: '臺東縣', area: '大武鄉' },
  { id: 9660, zipcode: 966, city: '臺東縣', area: '達仁鄉' },
  { id: 9700, zipcode: 970, city: '花蓮縣', area: '花蓮市' },
  { id: 9710, zipcode: 971, city: '花蓮縣', area: '新城鄉' },
  { id: 9720, zipcode: 972, city: '花蓮縣', area: '秀林鄉' },
  { id: 9730, zipcode: 973, city: '花蓮縣', area: '吉安鄉' },
  { id: 9740, zipcode: 974, city: '花蓮縣', area: '壽豐鄉' },
  { id: 9750, zipcode: 975, city: '花蓮縣', area: '鳳林鎮' },
  { id: 9760, zipcode: 976, city: '花蓮縣', area: '光復鄉' },
  { id: 9770, zipcode: 977, city: '花蓮縣', area: '豐濱鄉' },
  { id: 9780, zipcode: 978, city: '花蓮縣', area: '瑞穗鄉' },
  { id: 9790, zipcode: 979, city: '花蓮縣', area: '萬榮鄉' },
  { id: 9810, zipcode: 981, city: '花蓮縣', area: '玉里鎮' },
  { id: 9820, zipcode: 982, city: '花蓮縣', area: '卓溪鄉' },
  { id: 9830, zipcode: 983, city: '花蓮縣', area: '富里鄉' },
  { id: 8900, zipcode: 890, city: '金門縣', area: '金沙鎮' },
  { id: 8910, zipcode: 891, city: '金門縣', area: '金湖鎮' },
  { id: 8920, zipcode: 892, city: '金門縣', area: '金寧鄉' },
  { id: 8930, zipcode: 893, city: '金門縣', area: '金城鎮' },
  { id: 8940, zipcode: 894, city: '金門縣', area: '烈嶼鄉' },
  { id: 8960, zipcode: 896, city: '金門縣', area: '烏坵鄉' },
  { id: 2090, zipcode: 209, city: '連江縣', area: '南竿鄉' },
  { id: 2100, zipcode: 210, city: '連江縣', area: '北竿鄉' },
  { id: 2110, zipcode: 211, city: '連江縣', area: '莒光鄉' },
  { id: 2120, zipcode: 212, city: '連江縣', area: '東引鄉' },
]
