class AddressModel {
  constructor (cities) {
    this.cities = cities
  }

  parse (address) {
    let area = ''
    let city = ''
    for (const { city: checkCity } of this.cities) {
      if (address.indexOf(checkCity) === 0) {
        city = checkCity
        address = address.replace(city, '')
        break
      }
    }
    for (const { area: checkArea } of this.cities) {
      if (address.indexOf(checkArea) === 0) {
        area = checkArea
        address = address.replace(area, '')
        break
      }
    }

    return {
      // ...this.cities.find((one) => one.area === area && one.city === city),
      city,
      area,
      address,
    }
  }
}

export default AddressModel
